import { useEffect, useState } from "react";
import axios from "axios";
import { Case } from "../../types/cases.types";

export const useGetCases = (accessToken: string) => {
  const [cases, setCases] = useState<Case[]>([]);

  useEffect(() => {
    if (!accessToken) return;

    axios
      .get<Case[]>(`${process.env.REACT_APP_API_BASE_URL}/cases?unacknowledged=false`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setCases(response.data);
      });
  }, [accessToken]);

  return cases;
};
