import { useEffect, useState } from "react";
import axios from "axios";
import { Case } from "../../types/cases.types";

export const useGetCase = (accessToken: string, caseId: number) => {
  const [tblCase, setTblCase] = useState<Case>();
  const [loading, setLoading] = useState(false);

  const updateCase = async (caseToUpdate: Case) => {
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/Cases`,
        caseToUpdate,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!caseId || isNaN(caseId) || !accessToken) return;

    setLoading(true);

    axios
      .get<Case>(`${process.env.REACT_APP_API_BASE_URL}/Cases/${caseId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setTblCase(response.data);
      });
    setLoading(false);
  }, [accessToken, caseId]);

  return { tblCase, updateCase, loading };
};
