import { RouteComponentProps } from "react-router-dom";
import { useGetAccessToken } from "../hooks/useGetAccessToken";
import { useGetCase } from "../hooks/cases/useGetCase";
import { CaseDetailsContainer } from "../components/CaseDetails/CaseDetailsContainer";
import { ExpertPortalAuthenticationTemplate } from "../components/AuthenticationTemplate/AuthenticationTemplate";

export interface CaseDetailsPageProps
  extends RouteComponentProps<{ id: string }> {}

export const CaseDetailsPage = ({ match }: CaseDetailsPageProps) => {
  const caseId = match.params.id;
  const accessToken = useGetAccessToken();
  const { loading, tblCase, updateCase } = useGetCase(
    accessToken,
    parseInt(caseId)
  );

  return (
    <ExpertPortalAuthenticationTemplate>
      {tblCase && (
        <CaseDetailsContainer
          tblCase={tblCase}
          updateCase={updateCase}
          loading={loading}
        />
      )}
    </ExpertPortalAuthenticationTemplate>
  );
};
