import { useMemo } from "react";
import { Case } from "../../types/cases.types";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CasesTableProps, RowProps } from "./Cases.types";
import { useHistory } from "react-router-dom";

export const CasesTable = ({ cases }: CasesTableProps) => {
  const { push } = useHistory();

  const columns = useMemo<MRT_ColumnDef<Case>[]>(
    () => [
      {
        accessorKey: "patient.firstName",
        header: "First Name",
        size: 150,
      },
      {
        accessorKey: "patient.lastName",
        header: "Last Name",
        size: 150,
      },
      {
        accessorKey: "patient.homeAddress.streetAddress",
        header: "Address",
        size: 200,
      },
      {
        accessorKey: "patient.homeAddress.town",
        header: "Town",
        size: 150,
      },
      {
        accessorKey: "patient.homeAddress.postcode",
        header: "Postcode",
        size: 150,
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={cases}
      muiTableBodyRowProps={({ row: { original } }: RowProps) => ({
        onClick: () => {
          push(`cases/${original.id}`);
        },
        sx: {
          cursor: "pointer",
        },
      })}
    />
  );
};
