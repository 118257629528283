import { Alert, AlertTitle, Button } from "@mui/material";

export interface AlertSummaryProps {
  title: string;
}

export const AlertSummary = ({ title }: AlertSummaryProps) => {
  return (
    <Alert severity="info">
      <AlertTitle>{title}</AlertTitle>
      <div className="mb-10">Why not try some of our useful links?</div>
      <Button variant="contained">Unacknowledged Cases</Button>
    </Alert>
  );
};
