import { Alert, Button, Typography } from "@mui/material";
import { ClientDetails } from "./ClientDetails";
import { CaseDetailsContainerProps } from "./CaseDetails.types";
import { useEffect, useState } from "react";

export const CaseDetailsContainer = ({
  loading,
  tblCase,
  updateCase,
}: CaseDetailsContainerProps) => {
  const [caseForm, setCaseForm] = useState(tblCase);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleUpdate = () => {
    updateCase(caseForm);
    setShowSuccess(true);
  };

  useEffect(() => {
    setShowSuccess(false);
  }, [tblCase]);

  return (
    <div className="w-full pt-4 px-4 md:px-10">
      <Typography variant="h1" component="h1">
        Case Summary
      </Typography>

      <Typography variant="h2" component="h2">
        Client Details
      </Typography>
      <ClientDetails
        caseForm={caseForm}
        setCaseForm={setCaseForm}
        onChange={() => setShowSuccess(false)}
      />
      <div className="mt-5 mb-5">
        <Button
          variant="outlined"
          onClick={handleUpdate}
        >
          Update Details
        </Button>
      </div>
      {showSuccess && (
        <Alert severity="success">The Case details have been updated</Alert>
      )}
    </div>
  );
};
