import { TextField, Typography } from "@mui/material";
import { AddressSectionProps, FIELD_NAMES } from "./CaseDetails.types";
import { Address } from "../../types/cases.types";

export const AddressSection = ({
  address,
  setAddress,
  title,
  onChange,
}: AddressSectionProps) => {
  const { postcode, streetAddress, telephone, town } = address;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedAddress = {
      ...address,
      [name]: value,
    } as Address;
    setAddress(updatedAddress);
    if (onChange) onChange();
  };

  return (
    <div className="mt-3">
      <Typography variant="h3" component="h3">
        {title}
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-x-2 gap-y-4 mt-3">
        <TextField
          id="client-details-address-street"
          className="row-span-2"
          label={FIELD_NAMES.ADDRESS.STREET_ADDRESS}
          defaultValue={streetAddress}
          multiline
          rows={3}
          name="streetAddress"
          onChange={handleChange}
        />
        <TextField
          id="client-details-address-town"
          label={FIELD_NAMES.ADDRESS.TOWN}
          defaultValue={town}
          name="town"
          onChange={handleChange}
        />
        <TextField
          id="client-details-address-postcode"
          label={FIELD_NAMES.ADDRESS.POSTCODE}
          defaultValue={postcode}
          name="postcode"
          onChange={handleChange}
        />

        <TextField
          id="client-details-address-telephone"
          label={FIELD_NAMES.ADDRESS.TELEPHONE}
          defaultValue={telephone}
          name="telephone"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
