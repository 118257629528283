import { TextField } from "@mui/material";
import { AddressSection } from "./AddressSection";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ClientDetailsProps, FIELD_NAMES } from "./CaseDetails.types";
import { Address, Case } from "../../types/cases.types";

export const ClientDetails = ({
  caseForm,
  setCaseForm,
  onChange,
}: ClientDetailsProps) => {
  const { firstName, lastName, homeAddress, workAddress, dateOfBirth, mobile } =
    caseForm.patient;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedCase = {
      ...caseForm,
      patient: {
        ...caseForm.patient,
        [name]: value,
      },
    } as Case;
    setCaseForm(updatedCase);
    if (onChange) onChange();
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const updatedCase = {
        ...caseForm,
        patient: {
          ...caseForm.patient,
          dateOfBirth: date.toISOString(),
        },
      } as Case;

      setCaseForm(updatedCase);
      if (onChange) onChange();
    }
  };

  const handleUpdateAddress = (name: string, address: Address) => {
    setCaseForm({
      ...caseForm,
      patient: { ...caseForm.patient, [name]: address },
    });
  };

  return (
    <div className="mt-3 w-full">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-x-2 gap-y-4">
        <TextField
          id="client-details-first-name"
          label={FIELD_NAMES.FIRST_NAME}
          defaultValue={firstName}
          name="firstName"
          onChange={handleChange}
        />
        <TextField
          id="client-details-last-name"
          label={FIELD_NAMES.LAST_NAME}
          defaultValue={lastName}
          name="lastName"
          onChange={handleChange}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={FIELD_NAMES.DOB}
            defaultValue={new Date(dateOfBirth)}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
        <TextField
          id="client-details-mobile"
          label={FIELD_NAMES.MOBILE}
          defaultValue={mobile}
          name="mobile"
          onChange={handleChange}
        />
      </div>
      <div className="mt-5">
        <AddressSection
          address={{ ...homeAddress }}
          title={FIELD_NAMES.ADDRESS.HOME_ADDRESS}
          setAddress={(address: Address) =>
            handleUpdateAddress("homeAddress", address)
          }
          onChange={onChange}
        />
        <AddressSection
          address={{ ...workAddress }}
          title={FIELD_NAMES.ADDRESS.WORK_ADDRESS}
          setAddress={(address: Address) =>
            handleUpdateAddress("workAddress", address)
          }
          onChange={onChange}
        />
      </div>
    </div>
  );
};
