import { Address, Case } from "../../types/cases.types";

export interface AddressSectionProps {
  address: Address;
  setAddress: (address: Address) => void;
  title: string;
  onChange?: () => void;
}

export interface CaseDetailsContainerProps {
  tblCase: Case;
  loading: boolean;
  updateCase: (tblCase: Case) => void;
}

export interface ClientDetailsProps {
  caseForm: Case;
  setCaseForm: (caseForm: Case) => void;
  onChange?: () => void;
}

export const FIELD_NAMES = {
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  DOB: "Date of Birth",
  MOBILE: "Mobile",
  ADDRESS: {
    HOME_ADDRESS: "Home Address",
    WORK_ADDRESS: "Work Address",
    STREET_ADDRESS: "Street Address",
    TOWN: "Town",
    POSTCODE: "Postcode",
    TELEPHONE: "Telephone",
  },
};
