import { Sidebar } from "../Sidebar/Sidebar";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { LayoutProps } from "./Layout.types";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useGetCmsSettings } from "../../hooks/useGetCmsSettings";

export const Layout = ({ children }: LayoutProps) => {
  const settings = useGetCmsSettings();

  const { instance, inProgress, accounts } = useMsal();
  const user = accounts[0] || null;
  
  const handleLogin = () => {
    if (inProgress === InteractionStatus.None) {
      const loginRequest = {
        scopes: ["openid "],
        redirectUrl: '/unacknowledged'
      };
      instance.loginRedirect(loginRequest);
    }
  };

  const handleLogout = () => {
    instance.logout();
  };

  const matches = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = useState(matches);

  const stringAvatar = (name?: string) => {
    return {
      sx: {
        bgcolor: "#11c08e",
      },
      children: name ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}` : "",
    };
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" className="z-50 w-full">
        <Toolbar
          sx={{ backgroundColor: "#013648", height: "80px" }}
          className="flex justify-between"
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <div className="h-20">
            {settings && (
              <img className="h-full" src={settings.logoUrl} alt="logo" />
            )}
          </div>
          {user && <Avatar {...stringAvatar((user.idTokenClaims as any).name)} />}
        </Toolbar>
      </AppBar>
      <Sidebar
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        user={user}
        open={open}
        setOpen={setOpen}
        logoUrl={settings?.logoUrl}
      />
      <div className="mt-20 w-full">{children}</div>
    </Box>
  );
};
