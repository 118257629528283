import { Layout } from "./components/Layout/Layout";
import { Route, Switch } from "react-router-dom";
import { CaseDetails, CasesPage, PageNotFoundPage } from "./pages";
import { DischargeReportsDuePage } from "./pages/DischargeReportsDuePage";
import { InitialAssessmentDuePage } from "./pages/InitialAssessmentDuePage";
import { AppointmentsDuePage } from "./pages/AppointmentsDuePage";
import { UnacknowledgedDuePage } from "./pages/UnacknowledgedDuePage";
import { AdminPage } from "./pages/AdminPage";

function App() {
  return (
    <div className="">
      <Layout>
        <Switch>
          <Route exact path="/cases" component={CasesPage} />
          <Route path="/cases/:id" component={CaseDetails} />
          <Route
            exact
            path="/discharge-due"
            component={DischargeReportsDuePage}
          />
          <Route exact path="/ia-due" component={InitialAssessmentDuePage} />
          <Route
            exact
            path="/appointments-due"
            component={AppointmentsDuePage}
          />
          <Route
            exact
            path="/unacknowledged"
            component={UnacknowledgedDuePage}
          />
          <Route exact path="/admin" component={AdminPage} />

          <Route component={PageNotFoundPage} />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
