import { CasesTable } from "./CasesTable";
import { Typography } from "@mui/material";
import { CasesContainerProps } from "./Cases.types";

export const CasesContainer = ({ cases }: CasesContainerProps) => {
  return (
    <div className="w-full pt-4 px-4 md:px-10">
      <div className="mb-3">
        <Typography variant="h1" component="h1">
          Cases Overview
        </Typography>
      </div>
      <CasesTable cases={cases} />
    </div>
  );
};
