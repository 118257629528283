import { useGetAccessToken } from "../hooks/useGetAccessToken";
import { ExpertPortalAuthenticationTemplate } from "../components/AuthenticationTemplate/AuthenticationTemplate";
import { CasesContainer } from "../components/Cases/CasesContainer";
import { useGetCases } from "../hooks/cases/useGetCases";

export const UnacknowledgedDuePage = () => {
  const accessToken = useGetAccessToken();
  const cases = useGetCases(accessToken);
  return (
    <ExpertPortalAuthenticationTemplate>
      {cases && <CasesContainer cases={cases} />}
    </ExpertPortalAuthenticationTemplate>
  );
}
