import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { IconListItemButtonProps } from "./IconListItemButton.types";

export const IconListItemButton = ({
  onClick,
  text,
  icon,
}: IconListItemButtonProps) => {
  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};
