import axios from "axios";
import { useEffect, useState } from "react";

export const useGetQuery = <T>(accessToken: string, resource: string) => {
  const [data, setData] = useState<T[]>([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (!accessToken) return;

    axios
      .get<T[]>(
        `${process.env.REACT_APP_API_BASE_URL}/${resource}?searchName=${query ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setData(response.data);
      });
  }, [accessToken, resource, query]);

  return { data, setQuery };
};
