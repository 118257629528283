import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useGetAccessToken } from "../../hooks/useGetAccessToken";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { ROLES } from "../../msal/roles";
import { GraphUser, UserUpdate } from "../../types/user.types";
import { AdminContainerProps } from "./Admin.types";
import { useGetQuery } from "../../hooks/query/useGetQuery";
import { RESOURCE_TYPES } from "../../hooks/query/resources";
import { Company } from "../../types/companies.types";
import { TailSpin } from "react-loading-icons";

export const AdminContainer = ({ role }: AdminContainerProps) => {
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [adminForm, setAdminForm] = useState<UserUpdate>({
    role: ROLES.MEMBER,
  });

  const accessToken = useGetAccessToken();
  const { data: users, setQuery: setUserQuery } = useGetQuery<GraphUser>(
    accessToken,
    RESOURCE_TYPES.USERS
  );
  const { data: companies, setQuery: setCompanyQuery } = useGetQuery<Company>(
    accessToken,
    RESOURCE_TYPES.MEMBER_COMPANIES
  );

  const roles = [
    ROLES.ORGANISATION_ADMIN,
    ROLES.ORGANISATION,
    ROLES.PRACTITIONER,
    ROLES.MEMBER,
  ];
  const { updateUser, loading } = useUpdateUser(accessToken);

  if (role === ROLES.SUPER_USER) {
    roles.unshift(ROLES.SUPER_USER);
  }

  useEffect(() => {
    if (!adminForm.b2CUserId || !adminForm.companyId) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setIsSubmitting(false);
  }, [adminForm]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (!adminForm || !isValid) return;
    setIsSubmitting(false);
    updateUser(adminForm);
  };

  return (
    <div className="w-full pt-4 px-4 md:px-10">
      <Typography variant="h1" component="h1">
        Admin Dashboard
      </Typography>
      <Typography variant="h2" component="h2">
        Assign a user
      </Typography>
      <div className="my-4">
        {isSubmitting && !isValid && (
          <Alert severity="error">
            <AlertTitle>Validation Errors</AlertTitle>
            {!adminForm.b2CUserId && <div>You must specify a user</div>}
            {!adminForm.companyId && <div>You must specify a company</div>}
            {!adminForm.role && <div>You must specify a role</div>}
          </Alert>
        )}
      </div>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <Autocomplete
          disablePortal
          id="users-autocomplete"
          options={users.map((user) => ({
            label: user.displayName,
            id: user.id,
          }))}
          renderOption={(props, option: any) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          onChange={(_event, newValue: any) =>
            setAdminForm((prev) => ({
              ...prev,
              b2CUserId: newValue?.id ?? undefined,
            }))
          }
          onInputChange={(event: SyntheticEvent<Element, Event>) =>
            setUserQuery((event.target as HTMLInputElement).value)
          }
          sx={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="User" />}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            value={adminForm.role}
            onChange={(e) =>
              setAdminForm((prev) => ({ ...prev, role: e.target.value }))
            }
          >
            {roles.map((roleOption) => (
              <MenuItem key={roleOption} value={roleOption}>
                {roleOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          disablePortal
          id="company-autocomplete"
          options={companies.map((company) => ({
            label: company.name,
            id: company.id,
          }))}
          renderOption={(props, option: any) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          onChange={(_event, newValue: any) =>
            setAdminForm((prev) => ({
              ...prev,
              companyId: newValue?.id ?? undefined,
            }))
          }
          onInputChange={(event: SyntheticEvent<Element, Event>) =>
            setCompanyQuery((event.target as HTMLInputElement).value)
          }
          sx={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Company" />}
        />
      </div>
      <div className="grid grid-cols-1 md: grid-cols-2 mt-4">
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting && !isValid}
        >
          {loading ? <TailSpin height="24" /> : <>Update User</>}
        </Button>
      </div>
    </div>
  );
};
