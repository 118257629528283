import { useEffect, useState } from "react";
import axios from "axios";

export interface Settings {
  logoUrl: string;
}

export const useGetCmsSettings = () => {
  const [settings, setSettings] = useState<Settings>();

  useEffect(() => {
    axios
      .get<Settings>(
        `${process.env.REACT_APP_CMS_ENDPOINT}/cms/settings.json`,
        {}
      )
      .then((response) => {
        setSettings(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return settings;
};
