import axios from "axios";
import { useState } from "react";
import { UserUpdate } from "../types/user.types";

export const useUpdateUser = (accessToken: string) => {
  const [loading, setLoading] = useState(false);

  const updateUser = async (userToUpdate: UserUpdate) => {
    setLoading(true);
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/users`,
        userToUpdate,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error: any) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  return { updateUser, loading };
};
