import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { AccountInfo } from "@azure/msal-browser";
import { Link, useHistory } from "react-router-dom";
import { IconListItemButton } from "../IconListItemButton/IconListItemButton";
import { SidebarItems } from "./SidebarItems";
import { useGetCmsSettings } from "../../hooks/useGetCmsSettings";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export interface SidebarProps {
  handleLogin: () => void;
  handleLogout: () => void;
  user: AccountInfo;
  open: boolean;
  setOpen: (state: boolean) => void;
  logoUrl?: string;
}

export const Sidebar = ({
  handleLogin,
  handleLogout,
  user,
  open,
  setOpen,
  logoUrl,
}: SidebarProps) => {
  const { push } = useHistory();
  const matches = useMediaQuery("(min-width:600px)");

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLinkClick = (url: string) => {
    if (!matches) handleDrawerClose();
  };

  const sidebarWidth = matches ? 290 : "100%";

  return open ? (
    <Drawer
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sidebarWidth,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <div className="flex justify-between py-0 px-4 space-x-4 justify-end h-20 bg-tblue-1 w-screen md:w-full">
        {logoUrl && <img className="h-full" src={logoUrl} alt="logo" />}
        <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
          <ChevronLeftIcon />
        </IconButton>
      </div>

      <Divider />
      <List>
        {SidebarItems.map((item, index) => (
          <ListItemButton key={index} onClick={() => handleLinkClick(item.url)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Link
                  to={item.url}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {item.text}
                </Link>
              }
            />
          </ListItemButton>
        ))}
        {user ? (
          <IconListItemButton
            icon={<LogoutIcon />}
            onClick={handleLogout}
            text="Logout"
          />
        ) : (
          <IconListItemButton
            icon={<LoginIcon />}
            onClick={handleLogin}
            text="Login"
          />
        )}
      </List>

      <Divider />
    </Drawer>
  ) : (
    <></>
  );
};
