import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { protectedResources } from "../msal/authConfig";

export const useGetAccessToken = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    if (account && instance) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.cases.scopes.read,
          account: account,
        })
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch(() => {
          instance
            .acquireTokenPopup({
              scopes: protectedResources.cases.scopes.read,
            })
            .then((response) => {
              setAccessToken(response.accessToken);
            })
            .catch((error) => {
              console.error("Error acquiring the access token:", error);
            });
        });
    }
  }, [account, instance]);

  return accessToken;
};
