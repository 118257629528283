import { useMsal } from "@azure/msal-react";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { AuthenticationTemplateProps } from "./AuthenticationTemplate.types";
import { useEffect, useState } from "react";

export const ExpertPortalAuthenticationTemplate = ({
  children,
  allowedRoles,
  setRole,
}: AuthenticationTemplateProps) => {
  const { accounts, instance } = useMsal();
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isAllowed, setIsAllowed] = useState<boolean>(false);

  useEffect(() => {
    const evaluateUserRole = () => {
      if (!allowedRoles) {
        setIsAllowed(true);
        return true;
      }

      const account = accounts[0];
      const idTokenClaims = account?.idTokenClaims as { [key: string]: any };

      if (idTokenClaims && idTokenClaims["extension_Role"]) {
        const role = idTokenClaims["extension_Role"];
        setUserRole(role);
        setIsAllowed(allowedRoles.includes(role));
      }
    };

    evaluateUserRole();
  }, [accounts, allowedRoles]);

  useEffect(() => {
    if (userRole && setRole) {
      setRole(userRole);
    }
  }, [userRole, setRole]);

  if (accounts.length > 0 && isAllowed) {
    return <>{children}</>;
  }

  const handleResetPermissions = async () => {
    if (!instance) return;
    instance.logout();
  };

  const unauthorizedContent = (
    <>
      <div className="mb-4">
        <Typography variant="body1" component="p">
          Sorry, you do not have the required permissions to access this
          feature.
        </Typography>

        <Typography variant="body1" component="p">
          If your permissions have recently changed, you will need to log in
          again.
        </Typography>
      </div>
      <Button color="error" onClick={handleResetPermissions}>
        Reset Permissions
      </Button>
    </>
  );

  return (
    <div className="mx-auto w-full pt-4 px-10">
      <Alert severity="error">
        <AlertTitle>
          {accounts.length === 0
            ? "User Authentication Required"
            : "Insufficient Permissions"}
        </AlertTitle>
        {accounts.length === 0
          ? "Whoops! It appears you're not signed in. Please log in to access this feature."
          : unauthorizedContent}
      </Alert>
    </div>
  );
};
