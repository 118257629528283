import React, { useState } from "react";
import { AdminContainer } from "../components/Admin/AdminContainer";
import { ExpertPortalAuthenticationTemplate } from "../components/AuthenticationTemplate/AuthenticationTemplate";
import { ROLES } from "../msal/roles";

export const AdminPage = () => {
  const [role, setRole] = useState<string>();
  return (
    <ExpertPortalAuthenticationTemplate
      allowedRoles={[ROLES.SUPER_USER, ROLES.ORGANISATION_ADMIN]}
      setRole={setRole}
    >
      {role && <AdminContainer role={role} />}
    </ExpertPortalAuthenticationTemplate>
  );
};
