import TaskIcon from "@mui/icons-material/Task";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ArticleIcon from "@mui/icons-material/Article";
import { AppointmentsDuePage } from "../../pages/AppointmentsDuePage";
import { UnacknowledgedDuePage } from "../../pages/UnacknowledgedDuePage";
import { InitialAssessmentDuePage } from "../../pages/InitialAssessmentDuePage";
import { DischargeReportsDuePage } from "../../pages/DischargeReportsDuePage";

export const SidebarItems: {
  text: string;
  url: string;
  icon: React.ReactNode;
  page: React.ReactNode;
}[] = [
  {
    text: "Unacknowledged Due",
    url: "/unacknowledged",
    icon: <TaskIcon />,
    page: <UnacknowledgedDuePage />,
  },
  {
    text: "Appointments Due",
    url: "/appointments-due",
    icon: <CalendarMonthIcon />,
    page: <AppointmentsDuePage />,
  },
  {
    text: "Initial Assessments Due",
    url: "/ia-due",
    icon: <DocumentScannerIcon />,
    page: <InitialAssessmentDuePage />,
  },
  {
    text: "Discharge Reports Due",
    url: "/discharge-due",
    icon: <ArticleIcon />,
    page: <DischargeReportsDuePage />,
  },
];
